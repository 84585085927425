<template>
  <div class="about" id="about">
    <Nav/>
      <main id="main" role="main">
        <section id="lead">
            <h2><img src="@/assets/img/head_about.svg" alt="特別な神社"></h2>
            <TxtAnime>当神社は碓氷峠頂上標高一二〇〇mに位置し、自然豊かな軽井沢に鎮座する神社です。また、当神社は全国的にも珍しく県境にあるため、お社の中央で長野県と群馬県に分かれております。そのため、一つの神社でありながら二つの宗教法人が顕在し、熊野皇大神社（長野県）と熊野神社（群馬県）に分かれており、それぞれの宮司・責任役員・総代にて尊厳が維持されています。熊野皇大神社は、神社庁より特別神社（全国で4社のみ）の指定を受けており、特別なご神徳を得られます。特別神社の4社とは、御嶽神社2社と冨士山稲荷神社、そして熊野皇大神社です。</TxtAnime>
            <div class="box">
              <slick ref="slick" :options="slickOptions">
                <a href=""><img src="@/assets/img/aboutus01.jpg" alt=""></a>
                <a href=""><img src="@/assets/img/aboutus02.jpg" alt=""></a>
                <a href=""><img src="@/assets/img/aboutus05.jpg" alt=""></a>
                <a href=""><img src="@/assets/img/aboutus06.jpg" alt=""></a>
                <a href=""><img src="@/assets/img/aboutus07.jpg" alt=""></a>
                <a href=""><img src="@/assets/img/aboutus08.jpg" alt=""></a>
              </slick>
            </div>
            <div class="box lists">
              <ul class="nes-list is-circle">
                <li>ご祭神</li>
                <li>本宮（両県に鎮座）</li>
                <li>伊邪那美命（イザナミノミコト）</li>
                <li>日本武尊（ヤマトタケルノミコト）</li>
                <li>新宮（上州鎮座）</li>
                <li>速玉男命（ハヤタマオノミコト）</li>
                <li>那智宮（信州鎮座）</li>
                <li>天照皇大神（アマテラスオオミカミ）</li>
                <li>事解男命（コトサカオノミコト）</li>
              </ul>
            </div>
          </section>
      </main>
    <Foot/>
  </div>
</template>

<script>
import Foot from '@/components/Foot.vue'
import Nav from '@/components/Nav.vue'
import TxtAnime from '@/components/TxtAnime.vue'

import Slick from 'vue-slick'
import '../../node_modules/slick-carousel/slick/slick.css'

export default {
  name: 'Aboutus',
  components: {
    Foot,
    Nav,
    Slick,
    TxtAnime
  },

  data () {
    return {
      slickOptions: {
        dots: true,
        arrows: false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  p,
  ul {
      font-family: 'NuKinakoMochi-BarVtE-1'
  }
  .nes-list.is-circle li::before {
    color: #fff;
  }

  .box {
    ul {
      margin-left: 1rem;
    }
  }
</style>
